.footer-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-gray {
  height: 30vh;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 55px;
}

.footer-gray img {
  max-width: 70%;
}
.footer-gray h3 {
  font-family: "nova-mono";
  font-size: 21px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  margin: 0;
  color: #dadada;
}

.footer-logo-img {
  position: relative;
  margin-bottom: -12px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-logo-img img {
  max-width: 98%;
}

@media screen and (max-width: 1500px) {
  .footer-logo-img {
    margin-bottom: -8px;
  }
}

@media screen and (max-width: 600px) {
  .footer-logo-img {
    display: none;
  }

  .footer-gray {
    height: 15vh;
    gap: 20px;
  }
  .footer-gray img {
    max-width: 90%;
  }
  .footer-gray h3 {
    font-size: 16px;
  }
}
