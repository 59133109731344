.gradient-backround-box {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: black; */
}

/* .gradient-backround-box {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    49.31% 87.71% at 50% 50%,
    rgba(35, 35, 35, 0) 36%,
    #232323 100%
  );
} */

@media screen and (max-width: 600px) {
  .gradient-backround-box {
    min-height: 80vh;
    height: auto;
  }

  #video {
    min-height: 50vh;
  }
}
