.video-section-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 75vh;
  width: 63vw;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
}

.outer-border {
  border: 3px solid #cccccc;
  width: 1205.49px;
  height: 685.87px;
}

.inner-border {
  width: 1200px;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-border iframe {
  height: calc(100% - 15px);
  width: calc(100%);
  background-color: black;
  border: 0;
}

.video-section-line {
  max-width: 55%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1920px) {
  .video-section-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
  }
  .outer-border {
    width: 90%;
    height: 75%;
  }
  .inner-border {
    width: 100%;
    height: 100%;
    margin-top: -1px;
  }

  .inner-border iframe {
    width: 100%;
    height: 99%;
  }
}

@media screen and (max-width: 950px) {
  .video-section-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .video-section-box {
    width: calc(100% - 24px);
    height: calc(100% - 48px);
    justify-content: center;
    gap: 20px;
    padding: 24px 12px;
  }

  .outer-border {
    width: 100%;
    height: 80%;
    border: unset;
  }
  .inner-border {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }
  .inner-border iframe {
    width: 100%;
    height: 99%;
  }
}
