.card-section-box {
  position: relative;
  /* width: 100%;
  height: 100vh; */
  background: linear-gradient(180deg, #d9d9d9 0%, #b6b6b6 100%);
  aspect-ratio: 2/1;
}

.left-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  left: 15%;
  background-image: url("../../../assets/icons/common/left.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.right-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  right: 15%;
  background-image: url("../../../assets/icons/common/right.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.card-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
}

@media screen and (max-width: 950px) {
  .card-section-box {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }

  .right-nav {
    bottom: 20%;
    top: unset;
    margin: unset;
    width: 40px;
    height: 40px;
  }
  .left-nav {
    top: unset;
    bottom: 20%;
    margin: unset;
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .right-nav {
    bottom: 10%;
    width: 30px;
    height: 30px;
  }
  .left-nav {
    bottom: 10%;
    width: 30px;
    height: 30px;
  }
}
