/* Base Styles */
.mobile-header {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  background-image: url("../../../assets/images/backgorunds/gradient-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  padding-top: 25px;
  width: 40%;
}

.mobile-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mobile-menu-item img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.header-label {
  font-family: "nova-mono";
  font-size: 21px;
  color: var(--text-color);
  cursor: pointer;
  margin-block: unset;
}

.header-label.active {
  text-decoration: underline;
}

/* Hamburger Icon Styles */
.hamburger-icon {
  cursor: pointer;
  display: none; /* Hidden by default on larger screens */
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
}

.hamburger-icon .bar {
  width: 100%;
  height: 4px;
  background-color: var(--text-color);
  transition: 0.3s ease;
}

.hamburger-icon .bar.open {
  background-color: var(--text-color);
}

.hamburger-icon .bar:first-child.open {
  transform: rotate(45deg) translate(9px, 8px);
}

.hamburger-icon .bar:nth-child(2).open {
  opacity: 0;
}

.hamburger-icon .bar:last-child.open {
  transform: rotate(-45deg) translate(6px, -6px);
}

/* Media Query to display hamburger only on small screens */
@media (max-width: 600px) {
  .hamburger-icon {
    display: flex; /* Show hamburger on small screens */
  }

  .mobile-menu {
    display: none; /* Hide mobile menu by default on small screens */
  }

  .mobile-header .mobile-menu {
    display: flex; /* Show mobile menu when toggled open */
  }
}
