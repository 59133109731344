.social-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 20%;
}

@media screen and (max-width: 600px) {
  .social-container {
    gap: 20px;
    min-width: none;
  }

  .social-container img {
    max-width: 40px;
  }
}
