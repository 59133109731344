.header-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: 50px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.desktop-menu {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .desktop-menu {
    display: none;
  }
}
