.welcome-section-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6%;
  height: 84vh;
  max-width: 1112px;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 90px;
  justify-content: center;
}

.title-container h1 {
  font-family: "nova-slim";
  font-size: 64px;
  font-weight: 400;
  line-height: 77.25px;
  text-align: center;
  margin: 0;
  color: var(--text-color);
}

.title-container h2 {
  font-family: "nova-slim";
  font-size: 36px;
  font-weight: 400;
  line-height: 43.45px;
  text-align: center;
  margin: 0;
  color: var(--text-color);
}

.description-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.description-container p {
  font-family: "nova-mono";
  font-size: 20px;
  font-weight: 400;
  line-height: 27.87px;
  text-align: center;
  margin: 0;
  color: var(--text-color);
}

.welcome-decorative-line {
  height: 40px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1920px) {
  .welcome-section-box {
    padding-left: 24px;
    padding-right: 24px;
    gap: 60px;
  }
  .title-container h1 {
    font-size: 60px;
    line-height: 66px;
  }
  .title-container h2 {
    font-size: 32px;
    line-height: 46px;
  }
  .description-container {
    gap: 30px;
  }
  .welcome-decorative-line {
    height: 30px;
  }
}

@media screen and (max-width: 600px) {
  .welcome-section-box {
    padding-left: 24px;
    padding-right: 24px;
    gap: 20px;
  }
  .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .title-container h2 {
    font-size: 26px;
    line-height: 32px;
  }
  .description-container p {
    font-size: 16px;
    line-height: 20px;
  }
  .description-container {
    gap: 10px;
  }
  .welcome-decorative-line {
    height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .welcome-section-box {
    padding-left: 12px;
    padding-right: 12px;
    gap: 15px;
  }
  .title-container h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .title-container h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .description-container p {
    font-size: 14px;
    line-height: 20px;
  }
  .description-container {
    gap: 5px;
  }
  .welcome-decorative-line {
    height: 15px;
  }
}
