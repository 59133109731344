.sign-button-box {
  width: auto;
  height: 88px;
  min-height: 88px;
  position: relative;
  width: 534px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.sign-button {
  transition: 0.3s all linear;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.sign-button.simple {
  background-image: url("../../../assets/images/buttons/sign-simple.png");
}

.sign-button.hovered {
  background-image: url("../../../assets/images/buttons/sign-hovered.png");
}

.sign-button.active {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 600px) {
  .sign-button-box {
    width: auto;
    height: auto;
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
