.email-contact {
  min-width: 20%;
}

@media screen and (max-width: 600px) {
  .email-contact {
    min-width: unset;
    width: 100%;
  }

  .email-contact img {
    width: 100%;
  }
}
