:root {
  --background: #232323;
  --text-color: #cccccc;
}
iframe,
img {
  user-select: none;
}
body {
  transform: translate3d(0, 0, 0);
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "nova-mono";
  src: local("nova-mono"),
    url(./assets/fonts/Nova_Mono/NovaMono-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "nova-slim";
  src: local("nova-slim"),
    url(./assets/fonts/Nova_Slim/NovaSlim-Regular.ttf) format("truetype");
  font-display: swap;
}
