.logo-section-box {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
}

.logo-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
}

.spline {
  width: 100%;
  height: 100%;
}

/* .spline canvas {
  width: 100% !important;
  height: auto !important;
} */

@media screen and (max-width: 600px) {
  .logo-section-box {
    height: 50vh;
  }
}
