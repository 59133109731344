.header-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  cursor: pointer;
}

.header-item-icon {
  height: 25px;
  width: auto;
}

.header-item-hovered {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s all ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "nova-mono";
  font-size: 21px;
  font-weight: 400;
  line-height: 29.26px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #cccccc;
}

.header-item-hovered p {
  margin: 0;
}

.header-item-hovered img {
  max-width: 10px;
}

.header-item-hovered.active {
  opacity: 1;
  visibility: visible;
}
