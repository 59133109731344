.contact-section-box {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("../../../assets/images/backgorunds/contact-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-inner-box {
  padding-top: 10%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

@media screen and (max-width: 950px) {
  .contact-inner-box {
    padding-top: 15%;
  }

  .contact-holder {
    height: auto;
    margin-top: 10%;
    padding: 0 12px;
    width: calc(100% - 24px);
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .contact-section-box {
    height: 70vh;
  }
  .contact-inner-box {
    height: 40vh;
    padding-top: 5%;
  }
}
